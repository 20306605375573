<template>
  <BaseModal
    :title="_T('@Subscription popup title')"
    :modal-width="968"
    title-color="white"
    without-header-border
    background-image="url('/img/lead-capture/background.png')"
    header-background-color="rgba(255, 255, 255, 0.2)"
    close-button-color="white"
    @on-close-modal="onCloseModal"
  >
    <div class="lead-capture__body">
      <form action="" class="lead-capture__form">
        <p class="lead-capture__form-title">
          {{ _T("@Description subscription pop-up") }}
        </p>
        <div class="lead-capture__inputs">
          <InputText
            v-model="form.name"
            :validate="v.name"
            :maxlength="255"
            :caption="_T('@Name')"
            autocomplete-type="given-name"
          />
          <InputEmail
            v-model="form.email"
            :validate="v.email"
            caption="E-mail"
          />
        </div>
        <div class="lead-capture__checkbox-wrp">
          <BaseCheckbox
            v-model="form.isAgreementAccepted"
            :validate="v.isAgreementAccepted"
          >
            <span>
              {{ _T("@I agree with") }}
              <MyLink
                class="lead-capture__caption-link"
                name="privacy-policy"
                target="_blank"
              >
                {{ _T("@privacy policy") }}
              </MyLink>
            </span>
          </BaseCheckbox>
          <ButtonPrimary
            type="submit"
            :aria-label="_T('@Send')"
            :disabled="subscribeHandler.isHandled"
            @click.prevent="onSubscribe"
          >
            {{ _T("@Send") }}
          </ButtonPrimary>
        </div>
      </form>
      <div class="lead-capture__body-image">
        <img src="/img/lead-capture/mascot.png" alt="mascot" />
      </div>
    </div>
  </BaseModal>
</template>

<script setup>
import useVuelidate from "@vuelidate/core";
import InputText from "~~/modules/shared/inputs/InputText.vue";
import InputEmail from "~/modules/shared/inputs/InputEmail.vue";
import {
  successfulModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import {
  emailValidate,
  maxLengthValidate,
  requiredValidate,
  sameAsValidate,
} from "~/utils/validators";
import { modalFormStore } from "~/modules/shared/modals/store/modalFormStore";
import { useUserStore } from "~/store/userStore";
import { useSingletonHandler } from "~/uses/useSingletonHandler";
import { useAPI } from "~/uses/useMyFetch";
import BaseCheckbox from "~/modules/shared/inputs/BaseCheckbox.vue";
import ButtonPrimary from "~/modules/shared/buttons/ButtonPrimary.vue";
import { useAppearanceStore } from "~/store/appearanceStore";
import BaseModal from "~/modules/shared/modals/BaseModal.vue";

const modalStore = useModalStore();
const { setModalHasShown } = useAppearanceStore();
const formStore = modalFormStore();
const userStore = useUserStore();

const { currentUser: user } = storeToRefs(userStore);

onMounted(() => {
  document.documentElement.style.overflow = "hidden";

  if (null !== user.value) {
    form.name = user.value.name.firstName;
    form.email = user.value.email;
  }

  formStore.addForm({ v, id: "subscription" });
});

const successfulData = {
  title: _T("@Successful modal title"),
  description: _T("@Successful modal text"),
  isWithTimeout: true,
};

const form = reactive({
  name: "",
  email: "",
  isAgreementAccepted: true,
});

const rules = computed(() => ({
  name: {
    required: requiredValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 255),
  },
  email: {
    required: requiredValidate(),
    email: emailValidate(),
  },
  isAgreementAccepted: {
    sameAs: sameAsValidate("@You must accept the terms and conditions", true),
  },
}));

const v = useVuelidate(rules, form);

function onCloseModal() {
  setModalHasShown("leadCapture");
}

const subscribeHandler = useSingletonHandler(() =>
  useAPI("/marketing/event", {
    method: "POST",
    body: {
      event: "subscriptionWithCoupon",
      email: form.email,
      isConfirmed: true,
      name: form.name,
    },
  }).then(() => {
    onCloseModal();

    modalStore.toggleModal(successfulModal, successfulData);
  }),
);

async function onSubscribe() {
  if (formStore.isFormsReady()) {
    await subscribeHandler.handle();
  }
}

onUnmounted(() => {
  formStore.resetForms();
  document.documentElement.style.overflow = "auto";
});
</script>

<style lang="scss" scoped>
.lead-capture {
  position: relative;
  border-radius: 8px;

  @include mobile {
    position: static;
    border-radius: 0;
  }

  &__header {
    @include flex-container(row, flex-end, center);

    padding: 16px;
    gap: 16px;

    @include mobile {
      @include flex-container(row, space-between, center);

      border-bottom: 1px solid white;
      padding: 9px 16px;
    }
  }

  &__modal-name {
    display: none;

    @include mobile {
      display: block;

      @include font(16, 22, 700);
      letter-spacing: 0.02em;
      color: white;
    }
  }

  &__close {
    @include fixedHW(32px, 32px);
    z-index: 20;
    @include flex-container(row, center, center);
  }

  &__body {
    @include flex-container(row, space-between, center);
    gap: 16px;

    @include mobile {
      flex-direction: column-reverse;
    }
  }

  &__form {
    width: 100%;
    @include flex-container(column, space-between);

    gap: 24px;
  }

  &__form-title {
    width: min(100%, 363px);

    align-self: center;

    @include font(18, 26, 700);
    text-align: center;
  }

  &__inputs {
    @include flex-container(column, space-between, center);
    gap: 24px;
  }

  &__checkbox-wrp {
    width: min(100%, 363px);
    align-self: center;

    @include flex-container(column, center, center);
    gap: 24px;
  }

  &__caption-link {
    color: white;
  }

  &__body-image {
    width: 100%;
    height: 100%;

    @include flex-container(row, center, center);

    @include mobile {
      max-width: 280px;
      max-height: 280px;
    }
  }
}
</style>
