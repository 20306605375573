import { useCategoriesStore } from "~/store/categoriesStore";

export const specificationsStringify = (specifications) => {
  return Object.entries(specifications)
    .sort()
    .map(([slug, optionSlugs]) => {
      return slug + "=" + optionSlugs.sort().join(",");
    })
    .join(";");
};

export const filterConfig = {
  categorySlug: {
    source: "params",
    state: {
      encode: (value) => {
        return value;
      },
      decode: (value) => {
        return value;
      },
    },
    request: {
      encode: (value) => {
        return useCategoriesStore().getCategoryBySlug(value)?.id;
      },
      alias: "categoryId",
    },
  },
  page: {
    source: "query",
    state: {
      encode: (value) => {
        if (1 !== value) {
          return value;
        }
      },
      decode: (value) => {
        return parseInt(value);
      },
    },
    request: {
      encode: (value) => {
        return parseInt(value);
      },
      alias: "pageNum",
    },
  },
  limit: {
    source: "query",
    state: {
      encode: (value) => {
        if (24 !== value) {
          return value;
        }
      },
      decode: (value) => {
        return parseInt(value);
      },
    },
    request: {
      encode: (value) => {
        return parseInt(value);
      },
      alias: "pageSize",
    },
  },
  specifications: {
    source: "params",
    state: {
      encode: (value) => {
        if (0 !== Object.keys(value).length) {
          return specificationsStringify(value);
        }
      },
      decode: (value) => {
        const specifications = {};

        if (0 !== value.length) {
          value.split(";").forEach((specification) => {
            const [slug, options] = specification.split("=");

            if (0 !== slug.length && 0 !== options.length) {
              specifications[slug] = options.split(",");
            }
          });
        }

        return specifications;
      },
    },
  },
  minPrice: {
    source: "query",
    state: {
      encode: (value) => {
        if (undefined !== value && 0 !== parseInt(value)) {
          return parseInt(value);
        }
      },
      decode: (value) => {
        if (value.length > 0 && 0 !== parseInt(value)) {
          return parseInt(value);
        }
      },
    },
  },
  maxPrice: {
    source: "query",
    state: {
      encode: (value) => {
        if (undefined !== value && 0 !== parseInt(value)) {
          return parseInt(value);
        }
      },
      decode: (value) => {
        if (value.length > 0 && 0 !== parseInt(value)) {
          return parseInt(value);
        }
      },
    },
  },
  labels: {
    source: "query",
    state: {
      encode: (value) => {
        if (value.length > 0) {
          return value.join(",");
        }
      },
      decode: (value) => {
        return value.split(",");
      },
    },
    request: {
      encode: (value) => {
        if (value.length > 0) {
          return value;
        }
      },
    },
  },
  sort: {
    source: "query",
    state: {
      encode: (value) => {
        if (undefined !== value) {
          return value;
        }
      },
      decode: (value) => {
        if (value.length > 0) {
          return value;
        }
      },
    },
  },
  searchQuery: {
    source: "query",
    state: {
      encode: (value) => {
        if (undefined !== value) {
          return value;
        }
      },
      decode: (value) => {
        if (value.length > 0) {
          return value;
        }
      },
    },
  },
};
