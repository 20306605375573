import qs from "qs";
import { useLanguageStore } from "~/store/languageStore";
import { useFilterStore } from "~/store/filterStore";
import { filterConfig } from "~/config/filterParam.config";

export const getPagesQuery = (path) => {
  return qs.stringify({
    locale: useLanguageStore().language?.name,
    path,
    fields: ["metaTitle", "metaDescription"],
  });
};

export const getSelectionsQuery = (categoryCode) => {
  return qs.stringify({
    locale: useLanguageStore().language?.name,
    fields: ["title", "description", "metaTitle", "metaDescription"],
    specifications: filterConfig["specifications"].state.encode(
      useFilterStore().params.specifications,
    ),
    categoryCode,
  });
};
