import { Specification } from "~/models/specification.model";
import { useLanguageStore } from "~/store/languageStore";
import { useCategoriesStore } from "~/store/categoriesStore";

export class Product {
  constructor(dto) {
    this.dto = dto;
    this.id = dto.id;
    this.code = dto.code;
    this.sku = dto.sku;
    this.slug = dto.slug;
    this.name = dto.name;
    this.contents = dto.contents;
    this.status = dto.status;
    this.labels = dto.labels;
    this.reviews = dto.reviews;
    this.isOrderable = dto.isOrderable;
    this.isDeleted = dto.isDeleted;
    this.prices = dto.prices;
    this.manufacturer = dto.manufacturer;
    this.specifications = dto.specifications?.map(
      (specification) => new Specification(specification),
    );
    this.categories = dto.categories;
    this.images = dto.images;
    this.badges = dto.badges;
    this.attachments = dto.attachments;
    this.gifts = dto.gifts
      ? dto.gifts.map((dtoProduct) => new Product(dtoProduct))
      : [];
    this.extra = {};
  }

  static PRICE_TYPE = {
    CURRENT: "current",
    PREVIOUS: "previous",
  };

  getName() {
    if (null !== this.sku) {
      return cleanStringsConcat([getLocalizedName(this.name), this.sku]);
    }

    return getLocalizedName(this.name);
  }

  getPrice(type) {
    return this.prices.find((price) => price.type === type)?.money?.amount;
  }

  getDiscountAmount() {
    return (
      this.getPrice(Product.PRICE_TYPE.CURRENT) -
      this.getPrice(Product.PRICE_TYPE.PREVIOUS)
    );
  }

  getDiscountPercentage() {
    return Math.trunc(
      (this.getDiscountAmount() / this.getPrice(Product.PRICE_TYPE.PREVIOUS)) *
        100,
    );
  }

  getCurrency(type) {
    return this.prices.find((price) => price.type === type)?.money?.currency;
  }

  getImages(type) {
    const languageStore = useLanguageStore();
    const { getUserLanguage: lang } = storeToRefs(languageStore);

    const images = this.images
      .filter(({ locales }) => locales.includes(lang.value.name))
      .flatMap(({ thumbnails }) => thumbnails)
      .filter((thumbnail) => thumbnail.type === type)
      .map(({ url }) => url);

    if (0 === images.length) {
      return ["/img/photo-plug.svg"];
    }

    return images;
  }

  getMainImage(type) {
    return this.getImages(type)[0];
  }

  getDescription() {
    return this.getContent("description");
  }

  getDownloads() {
    const DOWNLOADS_TYPE = {
      INSTRUCTION: "Инструкция",
      DRIVER: "Драйвер",
      APPLICATION: "Приложения",
    };

    const downloadsNames = {
      [DOWNLOADS_TYPE.INSTRUCTION]: _T("@Instructions"),
      [DOWNLOADS_TYPE.DRIVER]: _T("@Drivers"),
      [DOWNLOADS_TYPE.APPLICATION]: _T("@Applications"),
    };

    const downloads = this.attachments.filter((attachment) =>
      Object.values(DOWNLOADS_TYPE).includes(attachment.group),
    );

    return downloads.map((downloadsItem) => {
      return {
        ...downloadsItem,
        name: downloadsNames[downloadsItem.group],
      };
    });
  }

  getVideos() {
    const VIDEO_TYPE = "Видео";

    return this.attachments
      .filter((attachment) => attachment.group === VIDEO_TYPE)
      .flatMap(({ files }) => files);
  }

  getContent(type) {
    const content = this.contents.find((content) => content.type === type);

    if (undefined === content) {
      return;
    }

    return getLocalizedName(content.body);
  }

  hasContent(type) {
    return this.contents.some((content) => content.type === type);
  }

  getWarrantyValue() {
    for (const specification of this.specifications) {
      const warranty = specification.items.find((specificationItem) => {
        return specificationItem.name.ru === "Гарантия, мес";
      });

      if (undefined !== warranty) {
        return getLocalizedName(warranty.option.value);
      }
    }
  }

  getWarrantyPlural() {
    if (undefined === this.getWarrantyValue()) {
      return;
    }

    const words = [
      {
        ru: "месяц",
        uk: "місяц",
      },
      {
        ru: "месяца",
        uk: "місяця",
      },
      {
        ru: "месяцев",
        uk: "місяців",
      },
    ];

    const translatedWords = words.map((word) => getLocalizedName(word));

    return pluralFormatter(this.getWarrantyValue(), translatedWords);
  }

  getReviewsAverageRating() {
    if (null === this.reviews.averageRating) {
      return 0;
    }

    return this.reviews.averageRating;
  }

  getFixedReviewsAverageRating(digits) {
    return this.getReviewsAverageRating().toFixed(digits);
  }

  isEqual(product) {
    return this.id === product.id;
  }

  getMainCategory() {
    const { getCategoryById } = useCategoriesStore();

    return getCategoryById(this.categories[0].id);
  }

  isActive() {
    const isActiveStatuses = ["inStock", "quickProduction", "preOrder"];

    return isActiveStatuses.includes(this.status);
  }

  hasPaidDeliveryCategory() {
    const { getCategoryById } = useCategoriesStore();

    return this.categories.some(({ id }) => {
      return !getCategoryById(id).hasFreeDelivery();
    });
  }

  hasFreeDelivery() {
    return (
      !this.hasPaidDeliveryCategory &&
      this.getPrice(Product.PRICE_TYPE.CURRENT) >= FREE_DELIVERY_AMOUNT
    );
  }

  getExtra(key) {
    return this.extra[key];
  }

  setExtra(key, value) {
    this.extra[key] = value;
  }
}
