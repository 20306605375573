import { filterConfig } from "~/config/filterParam.config";

export const initialState = () => {
  return {
    categorySlug: undefined,
    page: 1,
    limit: 24,
    specifications: {},
    minPrice: undefined,
    maxPrice: undefined,
    labels: [],
    sort: undefined,
    searchQuery: undefined,
  };
};

export const useFilterStore = defineStore("filterStore", {
  state() {
    return {
      params: initialState(),
    };
  },
  actions: {
    setPage(page) {
      this.params.page = page;
    },
    addSpecification(slug, optionSlug) {
      this.params.specifications[slug] = (
        this.params.specifications[slug] || []
      ).concat(optionSlug);
    },
    removeSpecification(slug, optionSlug) {
      this.params.specifications[slug] = this.params.specifications[
        slug
      ].filter((option) => option !== optionSlug);

      if (0 === this.params.specifications[slug].length) {
        delete this.params.specifications[slug];
      }
    },
    toggleSpecification(slug, optionSlug) {
      if (!this.hasSpecification(slug, optionSlug)) {
        return this.addSpecification(slug, optionSlug);
      }

      return this.removeSpecification(slug, optionSlug);
    },
    hasSpecification(slug, optionSlug) {
      return true === this.params.specifications[slug]?.includes(optionSlug);
    },
    addLabel(label) {
      if (!this.params.labels.includes(label)) {
        this.params.labels.push(label);
      }
    },
    removeLabel(label) {
      const labelIndex = this.params.labels.indexOf(label);

      if (labelIndex !== -1) {
        this.params.labels.splice(labelIndex, 1);
      }
    },
    changeSort(sort) {
      this.params.sort = sort;
    },
    setSearch(searchValue) {
      this.params.searchQuery = searchValue;
    },
    resetPrice() {
      this.params.minPrice = this.params.maxPrice = undefined;
    },
    initParams() {
      const route = useRoute();
      this.params = initialState();

      for (let paramKey in this.params) {
        const paramValue = route.params[paramKey] ?? route.query[paramKey];

        if (undefined !== paramValue) {
          this.params[paramKey] =
            filterConfig[paramKey].state.decode(paramValue);
        }
      }

      if (
        this.params.maxPrice > 0 &&
        this.params.minPrice > 0 &&
        this.params.minPrice > this.params.maxPrice
      ) {
        this.resetPrice();
      }
    },
  },
  getters: {
    getPrice: (state) => {
      return {
        minPrice: state.params.minPrice,
        maxPrice: state.params.maxPrice,
      };
    },
  },
  persist: {
    debug: true,
    storage: {
      setItem(store, state) {
        const parsedParams = JSON.parse(state).params;

        const navigationOptions = {
          params: {
            specifications: "",
          },
          query: {},
        };

        for (const paramKey in parsedParams) {
          const encodedParamValue = filterConfig[paramKey].state.encode(
            parsedParams[paramKey],
          );

          if (undefined !== encodedParamValue) {
            navigationOptions[filterConfig[paramKey].source][paramKey] =
              encodedParamValue;
          }
        }

        return navigateTo({
          ...navigationOptions,
        });
      },
      getItem(store) {},
    },
  },
});
