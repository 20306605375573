<template>
  <div ref="refModal" class="modal" tabindex="0" @keydown.esc="onCloseModal">
    <div v-on-click-outside="onCloseModal" class="modal__wrapper">
      <div class="modal__content">
        <div
          class="modal__header"
          :class="{ 'no-border': withoutHeaderBorder }"
        >
          <p v-if="undefined !== title" class="modal__title">{{ title }}</p>
          <ButtonClose
            :color="closeButtonColor"
            class="modal__close"
            @click.prevent="onCloseModal"
          />
        </div>
        <div class="modal__body">
          <slot />
        </div>
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script setup>
import ButtonClose from "~/modules/shared/buttons/ButtonClose.vue";
import { vOnClickOutside } from "@vueuse/components";

const props = defineProps({
  title: { type: String, required: false },
  modalWidth: { type: Number, required: false, default: 500 },
  backgroundColor: { type: String, required: false, default: "white" },
  titleColor: { type: String, required: false, default: "#0e0f0f" },
  closeButtonColor: { type: String, required: false, default: "black" },
  backgroundImage: { type: String, required: false, default: "none" },
  withoutHeaderBorder: { type: Boolean, required: false, default: false },
  headerBackgroundColor: { type: String, required: false, default: "none" },
});

const emits = defineEmits(["onCloseModal"]);

const refModal = ref(null);

const onCloseModal = () => {
  emits("onCloseModal");
};

onMounted(() => {
  refModal.value.focus();
});
</script>

<style scoped lang="scss">
.modal {
  display: flex;

  position: fixed;
  inset: 0;
  z-index: 510;

  background-color: rgb(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);

  cursor: auto;

  &__wrapper {
    max-width: calc(v-bind(modalWidth) * 1px);
    width: 100%;

    margin: auto;

    @include mobile {
      height: 100%;
      overflow: auto;
    }
  }

  &__content {
    position: relative;

    background-color: v-bind(backgroundColor);
    background-image: v-bind(backgroundImage);
    background-size: cover;
    border-radius: 24px;

    @include mobile {
      height: 100%;

      display: flex;
      flex-direction: column;

      border-radius: 0;
    }
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr 0.2fr;
    grid-template-rows: 1fr;
    grid-column-gap: 16px;

    align-items: center;

    border-bottom: 1px solid var(--color-sky-base);
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    background-color: v-bind(headerBackgroundColor);

    padding: 16px;

    &.no-border {
      border-bottom: none;
    }

    @include mobile {
      padding-block: 8px;
    }
  }

  &__title {
    @include font(20, 28);
    letter-spacing: 0.02em;
    color: v-bind(titleColor);
  }

  &__close {
    grid-column-start: 2;
    justify-self: end;
  }

  &__body {
    padding: 24px 16px;

    @include mobile {
      overflow: auto;
    }
  }
}
</style>
