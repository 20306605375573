export const useAppearanceStore = defineStore(
  "appearanceStore",
  () => {
    const sideFilterMenu = ref({
      isShown: false,
    });
    const modals = ref({
      ad: {
        wasShown: false,
      },
      leadCapture: {
        wasShown: false,
      },
    });

    const setModalHasShown = (modalName) => {
      modals.value[modalName].wasShown = true;
    };

    return { sideFilterMenu, modals, setModalHasShown };
  },
  {
    persist: {
      storage: persistedState.cookiesWithOptions({
        maxAge: 86400,
      }),
      paths: ["modals"],
    },
  },
);
