export const languageConfig = [
  {
    name: "ru",
    synonym: "ru",
    urlPrefix: "",
  },
  {
    name: "uk",
    synonym: "ua",
    urlPrefix: "/ua",
  },
];
