import { cleanUrlName } from "~/utils/strings.utils";
import { useCategoriesStore } from "~/store/categoriesStore";

export default defineNuxtRouteMiddleware(async (to, from) => {
  //Выброс ошибок на несуществующие пути
  const error = useError();

  if (import.meta.client && error.value && to.fullPath !== from.fullPath) {
    window.location.href = to.fullPath;
  }

  if (cleanUrlName(to.name) === "category") {
    const categoryStore = useCategoriesStore();
    const category = categoryStore.getCategoryBySlug(to.params.categorySlug);

    if (undefined === category) {
      throw createError({ statusCode: 404, fatal: true });
    }

    if (undefined === to.params.specifications) {
      to.params.specifications = "";
    }
  }

  if (cleanUrlName(to.name) === "search" && !to.query.searchQuery) {
    throw createError({ statusCode: 404, fatal: true });
  }
});
