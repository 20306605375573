import { useAPI } from "~/uses/useMyFetch";

export const useMaintenanceStore = defineStore("maintenanceStore", {
  state: () => {
    return {
      maintenance: null,
    };
  },
  actions: {
    async fetch() {
      await callOnce(async () => {
        return useAPI("/shared/maintenanceMode").then((data) => {
          this.maintenance = data;
        });
      });
    },
  },
  getters: {
    getIsEnabled: (state) => state.maintenance.isEnabled,
    getMessage: (state) => state.maintenance.message,
  },
});
