import { languageConfig } from "~/config/language.config";

export const cleanStringsConcat = (strings) => {
  return strings
    .join(" ")
    .replace(/ +(?= )/g, "")
    .trim();
};

export const getFullName = (name) => {
  return cleanStringsConcat([
    name.lastName,
    name.firstName,
    name.middleName ? name.middleName : "",
  ]);
};

export const cleanUrlName = (urlName) => urlName.replace(/^[a-z]+_/, "");

export const removeLocaleFromUrl = (url) => {
  const locales = languageConfig.map(({ synonym }) => synonym);
  const regexp = new RegExp(`^/(${locales.join("|")})(/|$)`);

  return url.replace(regexp, "/");
};
