import { default as indexSJTQLaii1vMeta } from "/usr/src/nuxt-app/modules/home/ui/layouts/index.vue?macro=true";
import { default as indexezlF6IF4PNMeta } from "/usr/src/nuxt-app/modules/catalog/ui/layouts/index.vue?macro=true";
import { default as index50fTxBoDhQMeta } from "/usr/src/nuxt-app/modules/productCatalog/ui/layouts/index.vue?macro=true";
import { default as index7lalHtr8ScMeta } from "/usr/src/nuxt-app/modules/static/warranty/index.vue?macro=true";
import { default as SummarybCDA1t7xu6Meta } from "/usr/src/nuxt-app/modules/product/ui/pages/Summary?macro=true";
import { default as SpecificationscGnz3pGUDMMeta } from "/usr/src/nuxt-app/modules/product/ui/pages/Specifications?macro=true";
import { default as ReviewsLp9cAgEaKBMeta } from "/usr/src/nuxt-app/modules/product/ui/pages/Reviews?macro=true";
import { default as Videoss4tCWMHOArMeta } from "/usr/src/nuxt-app/modules/product/ui/pages/Videos?macro=true";
import { default as Downloadsi2JmvjrbGmMeta } from "/usr/src/nuxt-app/modules/product/ui/pages/Downloads?macro=true";
import { default as indexQWkH1FcnhQMeta } from "/usr/src/nuxt-app/modules/product/ui/layouts/index.vue?macro=true";
import { default as index3JH41m7qH7Meta } from "/usr/src/nuxt-app/modules/static/contacts/index.vue?macro=true";
import { default as index5otAliN6NBMeta } from "/usr/src/nuxt-app/modules/static/cooperation/index.vue?macro=true";
import { default as index8toHTALCewMeta } from "/usr/src/nuxt-app/modules/static/privacyPolicy/index.vue?macro=true";
import { default as indexuXDRZXUVJwMeta } from "/usr/src/nuxt-app/modules/static/publicOffer/index.vue?macro=true";
import { default as index39qV6e4bUTMeta } from "/usr/src/nuxt-app/modules/checkout/components/index.vue?macro=true";
import { default as Comparisondzs4Z6XSdgMeta } from "/usr/src/nuxt-app/modules/comparison/components/Comparison.vue?macro=true";
import { default as indexvPvn3gXZUCMeta } from "/usr/src/nuxt-app/modules/static/delivery/index.vue?macro=true";
import { default as indexohyOKhsw77Meta } from "/usr/src/nuxt-app/modules/order/components/index.vue?macro=true";
import { default as indexbzUlwcxTjCMeta } from "/usr/src/nuxt-app/modules/static/landing/solarPowerPlant/index.vue?macro=true";
import { default as ProfilePcI75Uc1qfMeta } from "/usr/src/nuxt-app/modules/account/components/tabs/Profile.vue?macro=true";
import { default as OrdersGqgpmBuz1QMeta } from "/usr/src/nuxt-app/modules/account/components/tabs/Orders.vue?macro=true";
import { default as FavoritesObqtuYUkVwMeta } from "/usr/src/nuxt-app/modules/account/components/tabs/Favorites.vue?macro=true";
import { default as indexoNLrLOrvSlMeta } from "/usr/src/nuxt-app/modules/account/components/index.vue?macro=true";
import { default as index8ENwf69fp9Meta } from "/usr/src/nuxt-app/modules/lookupDevice/components/index.vue?macro=true";
import { default as indexRLRGX7c9uPMeta } from "/usr/src/nuxt-app/modules/static/application/jk-bms/index.vue?macro=true";
import { default as indexbmqbKMPIl0Meta } from "/usr/src/nuxt-app/modules/static/landing/autonomic/index.vue?macro=true";
import { default as indexhL90J2nn6yMeta } from "/usr/src/nuxt-app/modules/installationWork/pages/catalog/index.vue?macro=true";
import { default as indexcPqg33AOicMeta } from "/usr/src/nuxt-app/modules/installationWork/pages/article/index.vue?macro=true";
import { default as indextJiflr0ffZMeta } from "/usr/src/nuxt-app/modules/static/reviewsPolicy/index.vue?macro=true";
import { default as indexlhxQnMtihPMeta } from "/usr/src/nuxt-app/modules/static/loyaltyPolicy/index.vue?macro=true";
import { default as indexWIx4XwBFEXMeta } from "/usr/src/nuxt-app/modules/landingConstructor/pages/catalog/index.vue?macro=true";
import { default as indexRqV4dyM57aMeta } from "/usr/src/nuxt-app/modules/landingConstructor/pages/layouts/index.vue?macro=true";
import { default as index1b2Y5zuiR9Meta } from "/usr/src/nuxt-app/modules/purchaseFeedback/ui/layouts/index.vue?macro=true";
import { default as indexyXrpVuQIv5Meta } from "/usr/src/nuxt-app/modules/faq/ui/layout/index.vue?macro=true";
import { default as indexEoTjhDyyMvMeta } from "/usr/src/nuxt-app/modules/static/application/rbms-monitor/index.vue?macro=true";
import { default as index1P6iWxYmPLMeta } from "/usr/src/nuxt-app/modules/manuals/ui/layouts/index.vue?macro=true";
import { default as CalculatorPD8lfh3sODMeta } from "/usr/src/nuxt-app/modules/calculators/powerCalculator/components/Calculator.vue?macro=true";
import { default as CalculatorRqMMWlCyhhMeta } from "/usr/src/nuxt-app/modules/calculators/batteryCalculator/components/Calculator.vue?macro=true";
import { default as BlogvlK75MU7ZEMeta } from "/usr/src/nuxt-app/nuxt_modules/blog/ui/pages/Blog?macro=true";
import { default as Category59pLAXyEQeMeta } from "/usr/src/nuxt-app/nuxt_modules/blog/ui/pages/Category?macro=true";
import { default as Tag7zDbLnkkH4Meta } from "/usr/src/nuxt-app/nuxt_modules/blog/ui/pages/Tag?macro=true";
import { default as Postnt5zNWwpqoMeta } from "/usr/src/nuxt-app/nuxt_modules/blog/ui/pages/Post?macro=true";
import { default as BecomeAuthorgMIGjM6U6CMeta } from "/usr/src/nuxt-app/nuxt_modules/blog/ui/pages/BecomeAuthor?macro=true";
import { default as indexJEMxBbgnOZMeta } from "/usr/src/nuxt-app/nuxt_modules/blog/ui/layouts/index.vue?macro=true";
export default [
  {
    name: indexSJTQLaii1vMeta?.name ?? "ua_home",
    path: indexSJTQLaii1vMeta?.path ?? "/",
    meta: { ...(indexSJTQLaii1vMeta || {}), ...{"externalSeo":true} },
    alias: indexSJTQLaii1vMeta?.alias || [],
    redirect: indexSJTQLaii1vMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/home/ui/layouts/index.vue").then(m => m.default || m)
  },
  {
    name: indexSJTQLaii1vMeta?.name ?? "ru_home",
    path: indexSJTQLaii1vMeta?.path ?? "/ru",
    meta: { ...(indexSJTQLaii1vMeta || {}), ...{"externalSeo":true} },
    alias: indexSJTQLaii1vMeta?.alias || [],
    redirect: indexSJTQLaii1vMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/home/ui/layouts/index.vue").then(m => m.default || m)
  },
  {
    name: indexezlF6IF4PNMeta?.name ?? "ru_catalog",
    path: indexezlF6IF4PNMeta?.path ?? "/catalog",
    meta: indexezlF6IF4PNMeta || {},
    alias: indexezlF6IF4PNMeta?.alias || [],
    redirect: indexezlF6IF4PNMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/catalog/ui/layouts/index.vue").then(m => m.default || m)
  },
  {
    name: indexezlF6IF4PNMeta?.name ?? "ua_catalog",
    path: indexezlF6IF4PNMeta?.path ?? "/ua/catalog",
    meta: indexezlF6IF4PNMeta || {},
    alias: indexezlF6IF4PNMeta?.alias || [],
    redirect: indexezlF6IF4PNMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/catalog/ui/layouts/index.vue").then(m => m.default || m)
  },
  {
    name: index50fTxBoDhQMeta?.name ?? "ru_category",
    path: index50fTxBoDhQMeta?.path ?? "/:categorySlug/:specifications?",
    meta: index50fTxBoDhQMeta || {},
    alias: index50fTxBoDhQMeta?.alias || [],
    redirect: index50fTxBoDhQMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/productCatalog/ui/layouts/index.vue").then(m => m.default || m)
  },
  {
    name: index50fTxBoDhQMeta?.name ?? "ua_category",
    path: index50fTxBoDhQMeta?.path ?? "/ua/:categorySlug/:specifications?",
    meta: index50fTxBoDhQMeta || {},
    alias: index50fTxBoDhQMeta?.alias || [],
    redirect: index50fTxBoDhQMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/productCatalog/ui/layouts/index.vue").then(m => m.default || m)
  },
  {
    name: index50fTxBoDhQMeta?.name ?? "ru_search",
    path: index50fTxBoDhQMeta?.path ?? "/search/:specifications?",
    meta: index50fTxBoDhQMeta || {},
    alias: index50fTxBoDhQMeta?.alias || [],
    redirect: index50fTxBoDhQMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/productCatalog/ui/layouts/index.vue").then(m => m.default || m)
  },
  {
    name: index50fTxBoDhQMeta?.name ?? "ua_search",
    path: index50fTxBoDhQMeta?.path ?? "/ua/search/:specifications?",
    meta: index50fTxBoDhQMeta || {},
    alias: index50fTxBoDhQMeta?.alias || [],
    redirect: index50fTxBoDhQMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/productCatalog/ui/layouts/index.vue").then(m => m.default || m)
  },
  {
    name: index7lalHtr8ScMeta?.name ?? "ru_warranty",
    path: index7lalHtr8ScMeta?.path ?? "/warranty",
    meta: { ...(index7lalHtr8ScMeta || {}), ...{"externalSeo":true} },
    alias: index7lalHtr8ScMeta?.alias || [],
    redirect: index7lalHtr8ScMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/warranty/index.vue").then(m => m.default || m)
  },
  {
    name: index7lalHtr8ScMeta?.name ?? "ua_warranty",
    path: index7lalHtr8ScMeta?.path ?? "/ua/warranty",
    meta: { ...(index7lalHtr8ScMeta || {}), ...{"externalSeo":true} },
    alias: index7lalHtr8ScMeta?.alias || [],
    redirect: index7lalHtr8ScMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/warranty/index.vue").then(m => m.default || m)
  },
  {
    name: indexQWkH1FcnhQMeta?.name ?? "ru_product",
    path: indexQWkH1FcnhQMeta?.path ?? "/:categorySlug/:productSlug([0-9a-z-]+)",
    meta: indexQWkH1FcnhQMeta || {},
    alias: indexQWkH1FcnhQMeta?.alias || [],
    redirect: indexQWkH1FcnhQMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/layouts/index.vue").then(m => m.default || m),
    children: [
  {
    name: SummarybCDA1t7xu6Meta?.name ?? "ru_product_summary",
    path: SummarybCDA1t7xu6Meta?.path ?? "",
    meta: SummarybCDA1t7xu6Meta || {},
    alias: SummarybCDA1t7xu6Meta?.alias || [],
    redirect: SummarybCDA1t7xu6Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/pages/Summary").then(m => m.default || m)
  },
  {
    name: SpecificationscGnz3pGUDMMeta?.name ?? "ru_product_specifications",
    path: SpecificationscGnz3pGUDMMeta?.path ?? "specifications",
    meta: SpecificationscGnz3pGUDMMeta || {},
    alias: SpecificationscGnz3pGUDMMeta?.alias || [],
    redirect: SpecificationscGnz3pGUDMMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/pages/Specifications").then(m => m.default || m)
  },
  {
    name: ReviewsLp9cAgEaKBMeta?.name ?? "ru_product_reviews",
    path: ReviewsLp9cAgEaKBMeta?.path ?? "reviews",
    meta: ReviewsLp9cAgEaKBMeta || {},
    alias: ReviewsLp9cAgEaKBMeta?.alias || [],
    redirect: ReviewsLp9cAgEaKBMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/pages/Reviews").then(m => m.default || m)
  },
  {
    name: Videoss4tCWMHOArMeta?.name ?? "ru_product_videos",
    path: Videoss4tCWMHOArMeta?.path ?? "videos",
    meta: Videoss4tCWMHOArMeta || {},
    alias: Videoss4tCWMHOArMeta?.alias || [],
    redirect: Videoss4tCWMHOArMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/pages/Videos").then(m => m.default || m)
  },
  {
    name: Downloadsi2JmvjrbGmMeta?.name ?? "ru_product_downloads",
    path: Downloadsi2JmvjrbGmMeta?.path ?? "downloads",
    meta: Downloadsi2JmvjrbGmMeta || {},
    alias: Downloadsi2JmvjrbGmMeta?.alias || [],
    redirect: Downloadsi2JmvjrbGmMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/pages/Downloads").then(m => m.default || m)
  }
]
  },
  {
    name: indexQWkH1FcnhQMeta?.name ?? "ua_product",
    path: indexQWkH1FcnhQMeta?.path ?? "/ua/:categorySlug/:productSlug([0-9a-z-]+)",
    meta: indexQWkH1FcnhQMeta || {},
    alias: indexQWkH1FcnhQMeta?.alias || [],
    redirect: indexQWkH1FcnhQMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/layouts/index.vue").then(m => m.default || m),
    children: [
  {
    name: SummarybCDA1t7xu6Meta?.name ?? "ua_product_summary",
    path: SummarybCDA1t7xu6Meta?.path ?? "",
    meta: SummarybCDA1t7xu6Meta || {},
    alias: SummarybCDA1t7xu6Meta?.alias || [],
    redirect: SummarybCDA1t7xu6Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/pages/Summary").then(m => m.default || m)
  },
  {
    name: SpecificationscGnz3pGUDMMeta?.name ?? "ua_product_specifications",
    path: SpecificationscGnz3pGUDMMeta?.path ?? "specifications",
    meta: SpecificationscGnz3pGUDMMeta || {},
    alias: SpecificationscGnz3pGUDMMeta?.alias || [],
    redirect: SpecificationscGnz3pGUDMMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/pages/Specifications").then(m => m.default || m)
  },
  {
    name: ReviewsLp9cAgEaKBMeta?.name ?? "ua_product_reviews",
    path: ReviewsLp9cAgEaKBMeta?.path ?? "reviews",
    meta: ReviewsLp9cAgEaKBMeta || {},
    alias: ReviewsLp9cAgEaKBMeta?.alias || [],
    redirect: ReviewsLp9cAgEaKBMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/pages/Reviews").then(m => m.default || m)
  },
  {
    name: Videoss4tCWMHOArMeta?.name ?? "ua_product_videos",
    path: Videoss4tCWMHOArMeta?.path ?? "videos",
    meta: Videoss4tCWMHOArMeta || {},
    alias: Videoss4tCWMHOArMeta?.alias || [],
    redirect: Videoss4tCWMHOArMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/pages/Videos").then(m => m.default || m)
  },
  {
    name: Downloadsi2JmvjrbGmMeta?.name ?? "ua_product_downloads",
    path: Downloadsi2JmvjrbGmMeta?.path ?? "downloads",
    meta: Downloadsi2JmvjrbGmMeta || {},
    alias: Downloadsi2JmvjrbGmMeta?.alias || [],
    redirect: Downloadsi2JmvjrbGmMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/product/ui/pages/Downloads").then(m => m.default || m)
  }
]
  },
  {
    name: index3JH41m7qH7Meta?.name ?? "ru_contacts",
    path: index3JH41m7qH7Meta?.path ?? "/contacts",
    meta: { ...(index3JH41m7qH7Meta || {}), ...{"externalSeo":true} },
    alias: index3JH41m7qH7Meta?.alias || [],
    redirect: index3JH41m7qH7Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: index3JH41m7qH7Meta?.name ?? "ua_contacts",
    path: index3JH41m7qH7Meta?.path ?? "/ua/contacts",
    meta: { ...(index3JH41m7qH7Meta || {}), ...{"externalSeo":true} },
    alias: index3JH41m7qH7Meta?.alias || [],
    redirect: index3JH41m7qH7Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: index5otAliN6NBMeta?.name ?? "ru_cooperation",
    path: index5otAliN6NBMeta?.path ?? "/cooperation",
    meta: { ...(index5otAliN6NBMeta || {}), ...{"externalSeo":true} },
    alias: index5otAliN6NBMeta?.alias || [],
    redirect: index5otAliN6NBMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/cooperation/index.vue").then(m => m.default || m)
  },
  {
    name: index5otAliN6NBMeta?.name ?? "ua_cooperation",
    path: index5otAliN6NBMeta?.path ?? "/ua/cooperation",
    meta: { ...(index5otAliN6NBMeta || {}), ...{"externalSeo":true} },
    alias: index5otAliN6NBMeta?.alias || [],
    redirect: index5otAliN6NBMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/cooperation/index.vue").then(m => m.default || m)
  },
  {
    name: index8toHTALCewMeta?.name ?? "ru_privacy-policy",
    path: index8toHTALCewMeta?.path ?? "/privacy-policy",
    meta: { ...(index8toHTALCewMeta || {}), ...{"externalSeo":true} },
    alias: index8toHTALCewMeta?.alias || [],
    redirect: index8toHTALCewMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/privacyPolicy/index.vue").then(m => m.default || m)
  },
  {
    name: index8toHTALCewMeta?.name ?? "ua_privacy-policy",
    path: index8toHTALCewMeta?.path ?? "/ua/privacy-policy",
    meta: { ...(index8toHTALCewMeta || {}), ...{"externalSeo":true} },
    alias: index8toHTALCewMeta?.alias || [],
    redirect: index8toHTALCewMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/privacyPolicy/index.vue").then(m => m.default || m)
  },
  {
    name: indexuXDRZXUVJwMeta?.name ?? "ru_public-offer",
    path: indexuXDRZXUVJwMeta?.path ?? "/public-offer",
    meta: { ...(indexuXDRZXUVJwMeta || {}), ...{"externalSeo":true} },
    alias: indexuXDRZXUVJwMeta?.alias || [],
    redirect: indexuXDRZXUVJwMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/publicOffer/index.vue").then(m => m.default || m)
  },
  {
    name: indexuXDRZXUVJwMeta?.name ?? "ua_public-offer",
    path: indexuXDRZXUVJwMeta?.path ?? "/ua/public-offer",
    meta: { ...(indexuXDRZXUVJwMeta || {}), ...{"externalSeo":true} },
    alias: indexuXDRZXUVJwMeta?.alias || [],
    redirect: indexuXDRZXUVJwMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/publicOffer/index.vue").then(m => m.default || m)
  },
  {
    name: index39qV6e4bUTMeta?.name ?? "ru_checkout",
    path: index39qV6e4bUTMeta?.path ?? "/checkout",
    meta: index39qV6e4bUTMeta || {},
    alias: index39qV6e4bUTMeta?.alias || [],
    redirect: index39qV6e4bUTMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/checkout/components/index.vue").then(m => m.default || m)
  },
  {
    name: index39qV6e4bUTMeta?.name ?? "ua_checkout",
    path: index39qV6e4bUTMeta?.path ?? "/ua/checkout",
    meta: index39qV6e4bUTMeta || {},
    alias: index39qV6e4bUTMeta?.alias || [],
    redirect: index39qV6e4bUTMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/checkout/components/index.vue").then(m => m.default || m)
  },
  {
    name: Comparisondzs4Z6XSdgMeta?.name ?? "ru_comparison",
    path: Comparisondzs4Z6XSdgMeta?.path ?? "/comparison",
    meta: Comparisondzs4Z6XSdgMeta || {},
    alias: Comparisondzs4Z6XSdgMeta?.alias || [],
    redirect: Comparisondzs4Z6XSdgMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/comparison/components/Comparison.vue").then(m => m.default || m)
  },
  {
    name: Comparisondzs4Z6XSdgMeta?.name ?? "ua_comparison",
    path: Comparisondzs4Z6XSdgMeta?.path ?? "/ua/comparison",
    meta: Comparisondzs4Z6XSdgMeta || {},
    alias: Comparisondzs4Z6XSdgMeta?.alias || [],
    redirect: Comparisondzs4Z6XSdgMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/comparison/components/Comparison.vue").then(m => m.default || m)
  },
  {
    name: indexvPvn3gXZUCMeta?.name ?? "ru_delivery",
    path: indexvPvn3gXZUCMeta?.path ?? "/delivery",
    meta: { ...(indexvPvn3gXZUCMeta || {}), ...{"externalSeo":true} },
    alias: indexvPvn3gXZUCMeta?.alias || [],
    redirect: indexvPvn3gXZUCMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/delivery/index.vue").then(m => m.default || m)
  },
  {
    name: indexvPvn3gXZUCMeta?.name ?? "ua_delivery",
    path: indexvPvn3gXZUCMeta?.path ?? "/ua/delivery",
    meta: { ...(indexvPvn3gXZUCMeta || {}), ...{"externalSeo":true} },
    alias: indexvPvn3gXZUCMeta?.alias || [],
    redirect: indexvPvn3gXZUCMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/delivery/index.vue").then(m => m.default || m)
  },
  {
    name: indexohyOKhsw77Meta?.name ?? "ru_order",
    path: indexohyOKhsw77Meta?.path ?? "/order/:orderId",
    meta: indexohyOKhsw77Meta || {},
    alias: indexohyOKhsw77Meta?.alias || [],
    redirect: indexohyOKhsw77Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/order/components/index.vue").then(m => m.default || m)
  },
  {
    name: indexohyOKhsw77Meta?.name ?? "ua_order",
    path: indexohyOKhsw77Meta?.path ?? "/ua/order/:orderId",
    meta: indexohyOKhsw77Meta || {},
    alias: indexohyOKhsw77Meta?.alias || [],
    redirect: indexohyOKhsw77Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/order/components/index.vue").then(m => m.default || m)
  },
  {
    name: indexbzUlwcxTjCMeta?.name ?? "ru_solnechnyye-elektrostantsii-pod-klyuch",
    path: indexbzUlwcxTjCMeta?.path ?? "/solnechnyye-elektrostantsii-pod-klyuch",
    meta: indexbzUlwcxTjCMeta || {},
    alias: indexbzUlwcxTjCMeta?.alias || [],
    redirect: indexbzUlwcxTjCMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/landing/solarPowerPlant/index.vue").then(m => m.default || m)
  },
  {
    name: indexbzUlwcxTjCMeta?.name ?? "ua_solnechnyye-elektrostantsii-pod-klyuch",
    path: indexbzUlwcxTjCMeta?.path ?? "/ua/solnechnyye-elektrostantsii-pod-klyuch",
    meta: indexbzUlwcxTjCMeta || {},
    alias: indexbzUlwcxTjCMeta?.alias || [],
    redirect: indexbzUlwcxTjCMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/landing/solarPowerPlant/index.vue").then(m => m.default || m)
  },
  {
    name: indexoNLrLOrvSlMeta?.name ?? "ru_account",
    path: indexoNLrLOrvSlMeta?.path ?? "/account",
    meta: indexoNLrLOrvSlMeta || {},
    alias: indexoNLrLOrvSlMeta?.alias || [],
    redirect: indexoNLrLOrvSlMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/account/components/index.vue").then(m => m.default || m),
    children: [
  {
    name: ProfilePcI75Uc1qfMeta?.name ?? "ru_account_profile",
    path: ProfilePcI75Uc1qfMeta?.path ?? "",
    meta: ProfilePcI75Uc1qfMeta || {},
    alias: ProfilePcI75Uc1qfMeta?.alias || [],
    redirect: ProfilePcI75Uc1qfMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/account/components/tabs/Profile.vue").then(m => m.default || m)
  },
  {
    name: OrdersGqgpmBuz1QMeta?.name ?? "ru_account_orders",
    path: OrdersGqgpmBuz1QMeta?.path ?? "orders",
    meta: OrdersGqgpmBuz1QMeta || {},
    alias: OrdersGqgpmBuz1QMeta?.alias || [],
    redirect: OrdersGqgpmBuz1QMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/account/components/tabs/Orders.vue").then(m => m.default || m)
  },
  {
    name: FavoritesObqtuYUkVwMeta?.name ?? "ru_account_favorites",
    path: FavoritesObqtuYUkVwMeta?.path ?? "favorites",
    meta: FavoritesObqtuYUkVwMeta || {},
    alias: FavoritesObqtuYUkVwMeta?.alias || [],
    redirect: FavoritesObqtuYUkVwMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/account/components/tabs/Favorites.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexoNLrLOrvSlMeta?.name ?? "ua_account",
    path: indexoNLrLOrvSlMeta?.path ?? "/ua/account",
    meta: indexoNLrLOrvSlMeta || {},
    alias: indexoNLrLOrvSlMeta?.alias || [],
    redirect: indexoNLrLOrvSlMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/account/components/index.vue").then(m => m.default || m),
    children: [
  {
    name: ProfilePcI75Uc1qfMeta?.name ?? "ua_account_profile",
    path: ProfilePcI75Uc1qfMeta?.path ?? "",
    meta: ProfilePcI75Uc1qfMeta || {},
    alias: ProfilePcI75Uc1qfMeta?.alias || [],
    redirect: ProfilePcI75Uc1qfMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/account/components/tabs/Profile.vue").then(m => m.default || m)
  },
  {
    name: OrdersGqgpmBuz1QMeta?.name ?? "ua_account_orders",
    path: OrdersGqgpmBuz1QMeta?.path ?? "orders",
    meta: OrdersGqgpmBuz1QMeta || {},
    alias: OrdersGqgpmBuz1QMeta?.alias || [],
    redirect: OrdersGqgpmBuz1QMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/account/components/tabs/Orders.vue").then(m => m.default || m)
  },
  {
    name: FavoritesObqtuYUkVwMeta?.name ?? "ua_account_favorites",
    path: FavoritesObqtuYUkVwMeta?.path ?? "favorites",
    meta: FavoritesObqtuYUkVwMeta || {},
    alias: FavoritesObqtuYUkVwMeta?.alias || [],
    redirect: FavoritesObqtuYUkVwMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/account/components/tabs/Favorites.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index8ENwf69fp9Meta?.name ?? "ru_lookup-device",
    path: index8ENwf69fp9Meta?.path ?? "/lookup-device",
    meta: { ...(index8ENwf69fp9Meta || {}), ...{"externalSeo":true} },
    alias: index8ENwf69fp9Meta?.alias || [],
    redirect: index8ENwf69fp9Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/lookupDevice/components/index.vue").then(m => m.default || m)
  },
  {
    name: index8ENwf69fp9Meta?.name ?? "ua_lookup-device",
    path: index8ENwf69fp9Meta?.path ?? "/ua/lookup-device",
    meta: { ...(index8ENwf69fp9Meta || {}), ...{"externalSeo":true} },
    alias: index8ENwf69fp9Meta?.alias || [],
    redirect: index8ENwf69fp9Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/lookupDevice/components/index.vue").then(m => m.default || m)
  },
  {
    name: indexRLRGX7c9uPMeta?.name ?? "ru_application",
    path: indexRLRGX7c9uPMeta?.path ?? "/jk-bms",
    meta: indexRLRGX7c9uPMeta || {},
    alias: indexRLRGX7c9uPMeta?.alias || [],
    redirect: indexRLRGX7c9uPMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/application/jk-bms/index.vue").then(m => m.default || m)
  },
  {
    name: indexRLRGX7c9uPMeta?.name ?? "ua_application",
    path: indexRLRGX7c9uPMeta?.path ?? "/ua/jk-bms",
    meta: indexRLRGX7c9uPMeta || {},
    alias: indexRLRGX7c9uPMeta?.alias || [],
    redirect: indexRLRGX7c9uPMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/application/jk-bms/index.vue").then(m => m.default || m)
  },
  {
    name: indexbmqbKMPIl0Meta?.name ?? "ru_lp-autonomic",
    path: indexbmqbKMPIl0Meta?.path ?? "/lp-autonomic-energiya-tvoyey-zhizni",
    meta: indexbmqbKMPIl0Meta || {},
    alias: indexbmqbKMPIl0Meta?.alias || [],
    redirect: indexbmqbKMPIl0Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/landing/autonomic/index.vue").then(m => m.default || m)
  },
  {
    name: indexbmqbKMPIl0Meta?.name ?? "ua_lp-autonomic",
    path: indexbmqbKMPIl0Meta?.path ?? "/ua/lp-autonomic-energiya-tvoyey-zhizni",
    meta: indexbmqbKMPIl0Meta || {},
    alias: indexbmqbKMPIl0Meta?.alias || [],
    redirect: indexbmqbKMPIl0Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/landing/autonomic/index.vue").then(m => m.default || m)
  },
  {
    name: indexhL90J2nn6yMeta?.name ?? "ru_installation-work",
    path: indexhL90J2nn6yMeta?.path ?? "/installation-work",
    meta: { ...(indexhL90J2nn6yMeta || {}), ...{"externalSeo":true} },
    alias: indexhL90J2nn6yMeta?.alias || [],
    redirect: indexhL90J2nn6yMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/installationWork/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexhL90J2nn6yMeta?.name ?? "ua_installation-work",
    path: indexhL90J2nn6yMeta?.path ?? "/ua/installation-work",
    meta: { ...(indexhL90J2nn6yMeta || {}), ...{"externalSeo":true} },
    alias: indexhL90J2nn6yMeta?.alias || [],
    redirect: indexhL90J2nn6yMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/installationWork/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexcPqg33AOicMeta?.name ?? "ru_installation-work_article",
    path: indexcPqg33AOicMeta?.path ?? "/installation-work/:installationArticleSlug",
    meta: indexcPqg33AOicMeta || {},
    alias: indexcPqg33AOicMeta?.alias || [],
    redirect: indexcPqg33AOicMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/installationWork/pages/article/index.vue").then(m => m.default || m)
  },
  {
    name: indexcPqg33AOicMeta?.name ?? "ua_installation-work_article",
    path: indexcPqg33AOicMeta?.path ?? "/ua/installation-work/:installationArticleSlug",
    meta: indexcPqg33AOicMeta || {},
    alias: indexcPqg33AOicMeta?.alias || [],
    redirect: indexcPqg33AOicMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/installationWork/pages/article/index.vue").then(m => m.default || m)
  },
  {
    name: indextJiflr0ffZMeta?.name ?? "ru_reviews-policy",
    path: indextJiflr0ffZMeta?.path ?? "/reviews-policy",
    meta: indextJiflr0ffZMeta || {},
    alias: indextJiflr0ffZMeta?.alias || [],
    redirect: indextJiflr0ffZMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/reviewsPolicy/index.vue").then(m => m.default || m)
  },
  {
    name: indextJiflr0ffZMeta?.name ?? "ua_reviews-policy",
    path: indextJiflr0ffZMeta?.path ?? "/ua/reviews-policy",
    meta: indextJiflr0ffZMeta || {},
    alias: indextJiflr0ffZMeta?.alias || [],
    redirect: indextJiflr0ffZMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/reviewsPolicy/index.vue").then(m => m.default || m)
  },
  {
    name: indexlhxQnMtihPMeta?.name ?? "ru_loyalty-policy",
    path: indexlhxQnMtihPMeta?.path ?? "/loyalty-policy",
    meta: indexlhxQnMtihPMeta || {},
    alias: indexlhxQnMtihPMeta?.alias || [],
    redirect: indexlhxQnMtihPMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/loyaltyPolicy/index.vue").then(m => m.default || m)
  },
  {
    name: indexlhxQnMtihPMeta?.name ?? "ua_loyalty-policy",
    path: indexlhxQnMtihPMeta?.path ?? "/ua/loyalty-policy",
    meta: indexlhxQnMtihPMeta || {},
    alias: indexlhxQnMtihPMeta?.alias || [],
    redirect: indexlhxQnMtihPMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/loyaltyPolicy/index.vue").then(m => m.default || m)
  },
  {
    name: indexWIx4XwBFEXMeta?.name ?? "ru_landingPageList",
    path: indexWIx4XwBFEXMeta?.path ?? "/landing-page",
    meta: indexWIx4XwBFEXMeta || {},
    alias: indexWIx4XwBFEXMeta?.alias || [],
    redirect: indexWIx4XwBFEXMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/landingConstructor/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexWIx4XwBFEXMeta?.name ?? "ua_landingPageList",
    path: indexWIx4XwBFEXMeta?.path ?? "/ua/landing-page",
    meta: indexWIx4XwBFEXMeta || {},
    alias: indexWIx4XwBFEXMeta?.alias || [],
    redirect: indexWIx4XwBFEXMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/landingConstructor/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexRqV4dyM57aMeta?.name ?? "ru_landingPage",
    path: indexRqV4dyM57aMeta?.path ?? "/landing-page/:id",
    meta: indexRqV4dyM57aMeta || {},
    alias: indexRqV4dyM57aMeta?.alias || [],
    redirect: indexRqV4dyM57aMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/landingConstructor/pages/layouts/index.vue").then(m => m.default || m)
  },
  {
    name: indexRqV4dyM57aMeta?.name ?? "ua_landingPage",
    path: indexRqV4dyM57aMeta?.path ?? "/ua/landing-page/:id",
    meta: indexRqV4dyM57aMeta || {},
    alias: indexRqV4dyM57aMeta?.alias || [],
    redirect: indexRqV4dyM57aMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/landingConstructor/pages/layouts/index.vue").then(m => m.default || m)
  },
  {
    name: index1b2Y5zuiR9Meta?.name ?? "ru_purchase-feedback",
    path: index1b2Y5zuiR9Meta?.path ?? "/purchase-feedback/:orderId",
    meta: index1b2Y5zuiR9Meta || {},
    alias: index1b2Y5zuiR9Meta?.alias || [],
    redirect: index1b2Y5zuiR9Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/purchaseFeedback/ui/layouts/index.vue").then(m => m.default || m)
  },
  {
    name: index1b2Y5zuiR9Meta?.name ?? "ua_purchase-feedback",
    path: index1b2Y5zuiR9Meta?.path ?? "/ua/purchase-feedback/:orderId",
    meta: index1b2Y5zuiR9Meta || {},
    alias: index1b2Y5zuiR9Meta?.alias || [],
    redirect: index1b2Y5zuiR9Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/purchaseFeedback/ui/layouts/index.vue").then(m => m.default || m)
  },
  {
    name: indexyXrpVuQIv5Meta?.name ?? "ru_faq",
    path: indexyXrpVuQIv5Meta?.path ?? "/faq/:faqSlug",
    meta: indexyXrpVuQIv5Meta || {},
    alias: indexyXrpVuQIv5Meta?.alias || [],
    redirect: indexyXrpVuQIv5Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/faq/ui/layout/index.vue").then(m => m.default || m)
  },
  {
    name: indexyXrpVuQIv5Meta?.name ?? "ua_faq",
    path: indexyXrpVuQIv5Meta?.path ?? "/ua/faq/:faqSlug",
    meta: indexyXrpVuQIv5Meta || {},
    alias: indexyXrpVuQIv5Meta?.alias || [],
    redirect: indexyXrpVuQIv5Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/faq/ui/layout/index.vue").then(m => m.default || m)
  },
  {
    name: indexEoTjhDyyMvMeta?.name ?? "ru_rbmsMonitor",
    path: indexEoTjhDyyMvMeta?.path ?? "/rbms-monitor",
    meta: indexEoTjhDyyMvMeta || {},
    alias: indexEoTjhDyyMvMeta?.alias || [],
    redirect: indexEoTjhDyyMvMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/application/rbms-monitor/index.vue").then(m => m.default || m)
  },
  {
    name: indexEoTjhDyyMvMeta?.name ?? "ua_rbmsMonitor",
    path: indexEoTjhDyyMvMeta?.path ?? "/ua/rbms-monitor",
    meta: indexEoTjhDyyMvMeta || {},
    alias: indexEoTjhDyyMvMeta?.alias || [],
    redirect: indexEoTjhDyyMvMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/static/application/rbms-monitor/index.vue").then(m => m.default || m)
  },
  {
    name: index1P6iWxYmPLMeta?.name ?? "ru_manuals",
    path: index1P6iWxYmPLMeta?.path ?? "/manuals/:manualSlug",
    meta: index1P6iWxYmPLMeta || {},
    alias: index1P6iWxYmPLMeta?.alias || [],
    redirect: index1P6iWxYmPLMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/manuals/ui/layouts/index.vue").then(m => m.default || m)
  },
  {
    name: index1P6iWxYmPLMeta?.name ?? "ua_manuals",
    path: index1P6iWxYmPLMeta?.path ?? "/ua/manuals/:manualSlug",
    meta: index1P6iWxYmPLMeta || {},
    alias: index1P6iWxYmPLMeta?.alias || [],
    redirect: index1P6iWxYmPLMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/manuals/ui/layouts/index.vue").then(m => m.default || m)
  },
  {
    name: CalculatorPD8lfh3sODMeta?.name ?? "ru_powerCalculator",
    path: CalculatorPD8lfh3sODMeta?.path ?? "/power-calculator",
    meta: { ...(CalculatorPD8lfh3sODMeta || {}), ...{"externalSeo":true} },
    alias: CalculatorPD8lfh3sODMeta?.alias || [],
    redirect: CalculatorPD8lfh3sODMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/calculators/powerCalculator/components/Calculator.vue").then(m => m.default || m)
  },
  {
    name: CalculatorPD8lfh3sODMeta?.name ?? "ua_powerCalculator",
    path: CalculatorPD8lfh3sODMeta?.path ?? "/ua/power-calculator",
    meta: { ...(CalculatorPD8lfh3sODMeta || {}), ...{"externalSeo":true} },
    alias: CalculatorPD8lfh3sODMeta?.alias || [],
    redirect: CalculatorPD8lfh3sODMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/calculators/powerCalculator/components/Calculator.vue").then(m => m.default || m)
  },
  {
    name: CalculatorRqMMWlCyhhMeta?.name ?? "ru_calculator",
    path: CalculatorRqMMWlCyhhMeta?.path ?? "/calculator",
    meta: { ...(CalculatorRqMMWlCyhhMeta || {}), ...{"externalSeo":true} },
    alias: CalculatorRqMMWlCyhhMeta?.alias || [],
    redirect: CalculatorRqMMWlCyhhMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/calculators/batteryCalculator/components/Calculator.vue").then(m => m.default || m)
  },
  {
    name: CalculatorRqMMWlCyhhMeta?.name ?? "ua_calculator",
    path: CalculatorRqMMWlCyhhMeta?.path ?? "/ua/calculator",
    meta: { ...(CalculatorRqMMWlCyhhMeta || {}), ...{"externalSeo":true} },
    alias: CalculatorRqMMWlCyhhMeta?.alias || [],
    redirect: CalculatorRqMMWlCyhhMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/modules/calculators/batteryCalculator/components/Calculator.vue").then(m => m.default || m)
  },
  {
    name: indexJEMxBbgnOZMeta?.name ?? "ru_blog",
    path: indexJEMxBbgnOZMeta?.path ?? "/blog",
    meta: indexJEMxBbgnOZMeta || {},
    alias: indexJEMxBbgnOZMeta?.alias || [],
    redirect: indexJEMxBbgnOZMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/nuxt_modules/blog/ui/layouts/index.vue").then(m => m.default || m),
    children: [
  {
    name: BlogvlK75MU7ZEMeta?.name ?? "ru_blog_main",
    path: BlogvlK75MU7ZEMeta?.path ?? "",
    meta: { ...(BlogvlK75MU7ZEMeta || {}), ...{"externalSeo":true} },
    alias: BlogvlK75MU7ZEMeta?.alias || [],
    redirect: BlogvlK75MU7ZEMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/nuxt_modules/blog/ui/pages/Blog").then(m => m.default || m)
  },
  {
    name: Category59pLAXyEQeMeta?.name ?? "ru_blog_category",
    path: Category59pLAXyEQeMeta?.path ?? "category/:categorySlug",
    meta: Category59pLAXyEQeMeta || {},
    alias: Category59pLAXyEQeMeta?.alias || [],
    redirect: Category59pLAXyEQeMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/nuxt_modules/blog/ui/pages/Category").then(m => m.default || m)
  },
  {
    name: Tag7zDbLnkkH4Meta?.name ?? "ru_blog_tag",
    path: Tag7zDbLnkkH4Meta?.path ?? "tag/:tagSlug",
    meta: Tag7zDbLnkkH4Meta || {},
    alias: Tag7zDbLnkkH4Meta?.alias || [],
    redirect: Tag7zDbLnkkH4Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/nuxt_modules/blog/ui/pages/Tag").then(m => m.default || m)
  },
  {
    name: Postnt5zNWwpqoMeta?.name ?? "ru_blog_post",
    path: Postnt5zNWwpqoMeta?.path ?? ":postSlug",
    meta: Postnt5zNWwpqoMeta || {},
    alias: Postnt5zNWwpqoMeta?.alias || [],
    redirect: Postnt5zNWwpqoMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/nuxt_modules/blog/ui/pages/Post").then(m => m.default || m)
  },
  {
    name: BecomeAuthorgMIGjM6U6CMeta?.name ?? "ru_blog_become-author",
    path: BecomeAuthorgMIGjM6U6CMeta?.path ?? "become-author",
    meta: BecomeAuthorgMIGjM6U6CMeta || {},
    alias: BecomeAuthorgMIGjM6U6CMeta?.alias || [],
    redirect: BecomeAuthorgMIGjM6U6CMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/nuxt_modules/blog/ui/pages/BecomeAuthor").then(m => m.default || m)
  }
]
  },
  {
    name: indexJEMxBbgnOZMeta?.name ?? "ua_blog",
    path: indexJEMxBbgnOZMeta?.path ?? "/ua/blog",
    meta: indexJEMxBbgnOZMeta || {},
    alias: indexJEMxBbgnOZMeta?.alias || [],
    redirect: indexJEMxBbgnOZMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/nuxt_modules/blog/ui/layouts/index.vue").then(m => m.default || m),
    children: [
  {
    name: BlogvlK75MU7ZEMeta?.name ?? "ua_blog_main",
    path: BlogvlK75MU7ZEMeta?.path ?? "",
    meta: { ...(BlogvlK75MU7ZEMeta || {}), ...{"externalSeo":true} },
    alias: BlogvlK75MU7ZEMeta?.alias || [],
    redirect: BlogvlK75MU7ZEMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/nuxt_modules/blog/ui/pages/Blog").then(m => m.default || m)
  },
  {
    name: Category59pLAXyEQeMeta?.name ?? "ua_blog_category",
    path: Category59pLAXyEQeMeta?.path ?? "category/:categorySlug",
    meta: Category59pLAXyEQeMeta || {},
    alias: Category59pLAXyEQeMeta?.alias || [],
    redirect: Category59pLAXyEQeMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/nuxt_modules/blog/ui/pages/Category").then(m => m.default || m)
  },
  {
    name: Tag7zDbLnkkH4Meta?.name ?? "ua_blog_tag",
    path: Tag7zDbLnkkH4Meta?.path ?? "tag/:tagSlug",
    meta: Tag7zDbLnkkH4Meta || {},
    alias: Tag7zDbLnkkH4Meta?.alias || [],
    redirect: Tag7zDbLnkkH4Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/nuxt_modules/blog/ui/pages/Tag").then(m => m.default || m)
  },
  {
    name: Postnt5zNWwpqoMeta?.name ?? "ua_blog_post",
    path: Postnt5zNWwpqoMeta?.path ?? ":postSlug",
    meta: Postnt5zNWwpqoMeta || {},
    alias: Postnt5zNWwpqoMeta?.alias || [],
    redirect: Postnt5zNWwpqoMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/nuxt_modules/blog/ui/pages/Post").then(m => m.default || m)
  },
  {
    name: BecomeAuthorgMIGjM6U6CMeta?.name ?? "ua_blog_become-author",
    path: BecomeAuthorgMIGjM6U6CMeta?.path ?? "become-author",
    meta: BecomeAuthorgMIGjM6U6CMeta || {},
    alias: BecomeAuthorgMIGjM6U6CMeta?.alias || [],
    redirect: BecomeAuthorgMIGjM6U6CMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/nuxt_modules/blog/ui/pages/BecomeAuthor").then(m => m.default || m)
  }
]
  }
]