export const normalizeDate = (date) => {
  const newDate = new Date(date * 1000);
  let day = newDate.getDate();
  if (day < 10) {
    day = "0" + day;
  }
  let month = newDate.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  const year = newDate.getFullYear();

  return day + "." + month + "." + year;
};

// Обвертка для изображений полученных с контентфула
export const useNormalizeCtfUrl = (url) => {
  if (undefined === url || 0 === url.length) {
    return "/img/photo-plug.svg";
  }

  const config = useRuntimeConfig();
  const urlToBtoa = btoa("https:" + url);

  if (config.public.appUrl === "http://localhost:3000") {
    return "https://dev.logicpower.ua" + "/static/assets/" + urlToBtoa;
  }

  return config.public.appUrl + "/static/assets/" + urlToBtoa;
};

export const normalizeStrapiUrl = (url) => {
  if (undefined === url || 0 === url.length) {
    return "/img/photo-plug.svg";
  }

  return useRuntimeConfig().public.strapiBaseUrl + url;
};
