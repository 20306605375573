import { useLanguageStore } from "~/store/languageStore";
import { Product } from "~/models/product.model";

export const FREE_DELIVERY_AMOUNT = 2000;

const deliveryTypes = {
  warehouse: "warehouse",
  courier: "courier",
};

export const deliveryMethod = {
  [deliveryTypes.warehouse]: formatWarehouseType,
  [deliveryTypes.courier]: formatCourierType,
};

function formatCourierType() {
  return _T("@Address delivery");
}

function formatWarehouseType(type) {
  return type.warehouse.type === "postMachine"
    ? _T("@Post box")
    : _T("@Post office");
}

function formatAddressName(address) {
  if (!address) {
    return;
  }

  return {
    ru: address.ru ? address.ru : address.uk !== null ? address.uk : undefined,
    uk: address.uk ? address.uk : address.ru !== null ? address.ru : undefined,
  };
}

export const formatAddress = (delivery) => {
  const languageStore = useLanguageStore();
  const language = languageStore.getUserLanguage;

  const region = formatAddressName(delivery.region.name)?.[language.name];
  const city = formatAddressName(delivery.city.name)?.[language.name];
  const warehouse = delivery.warehouse?.name
    ? formatAddressName(delivery.warehouse?.name)?.[language.name]
    : "";
  const address = delivery.warehouse?.address
    ? formatAddressName(delivery.warehouse?.address)?.[language.name]
    : "";
  const street = delivery.street?.name
    ? formatAddressName(delivery.street?.name)?.[language.name]
    : "";
  const house = delivery.house ? delivery.house : "";
  const flat = delivery.flat ? `Квартира ${delivery.flat}` : "";

  return `${region} ${_T("@Region")}, ${city}, ${
    warehouse ? `${_T("@Department")} №${warehouse}` : ""
  } ${address || ""} ${street} ${house} ${flat}`;
};

export const isFreeDeliveryOrder = (order) => {
  if ("courier" === order.delivery.type.method) {
    return false;
  }

  const hasPaidCategory = order.products.some(({ product }) =>
    new Product(product).hasPaidDeliveryCategory(),
  );

  if (hasPaidCategory) {
    return false;
  }

  return order.totalSalePrice.amount >= FREE_DELIVERY_AMOUNT;
};

export const getProductDeliveryOptions = (product) => [
  {
    title: "@Pickup from branch",
    icon: "nova-poshta-icon.svg",
    terms: {
      text: product.hasFreeDelivery() ? "@Free shipping" : "@Paid",
      color: product.hasFreeDelivery() ? "#159822" : "#2B2B2B",
    },
    isFreeDelivery: product.hasFreeDelivery(),
  },
  {
    title: "@Address delivery",
    icon: "nova-poshta-icon.svg",
    terms: {
      text: "@Paid",
      color: "#2B2B2B",
    },
    isFreeDelivery: false,
  },
];
