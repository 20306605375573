import { useMaintenanceStore } from "~/store/maintenanceStore";
import { useCategoriesStore } from "~/store/categoriesStore";
import { useI18nStore } from "~/store/i18nStore";
import { useStrapiApi } from "~/uses/useMyFetch";
import { getPagesQuery } from "~/utils/strapiQuery.utils";
import { removeLocaleFromUrl } from "~/utils/strings.utils";
import { useLanguageStore } from "~/store/languageStore";

export default defineNuxtRouteMiddleware(async (to, from) => {
  let meta;

  const fetchPageMeta = async (path) => {
    return useStrapiApi(`/pages?${getPagesQuery(removeLocaleFromUrl(path))}`, {
      timeout: 1000,
    })
      .then(({ data }) => {
        meta = data;
      })
      .catch(() => {});
  };

  const initialPromises = () => [
    new Promise((resolve) => {
      resolve(useMaintenanceStore().fetch());
    }),
    new Promise((resolve) => {
      resolve(useCategoriesStore().fetch());
    }),
    new Promise((resolve) => {
      resolve(useI18nStore().fetch());
    }),
    new Promise((resolve) => {
      resolve(
        useStrapiApi("/redirects/lookup", {
          query: {
            sourcePath: to.path,
          },
          timeout: 1000,
        })
          .then(({ data }) => {
            if (data.targetPath !== null) {
              return navigateTo(data.targetPath, { redirectCode: 301 });
            }
          })
          .catch(() => {}),
      );
    }),
    new Promise((resolve) => {
      if (true === to.meta.externalSeo) {
        resolve(fetchPageMeta(to.path));
      }

      resolve();
    }),
  ];

  if (import.meta.server) {
    useLanguageStore().setLanguage(to.name.match(/[^_]*/)[0]);

    await Promise.all(initialPromises());

    useSeoMeta({
      title: meta?.metaTitle || "LogicPower",
      description: meta?.metaDescription,
    });
  }

  if (undefined !== from.name && true === to.meta.externalSeo) {
    await fetchPageMeta(to.path);

    useSeoMeta({
      title: meta?.metaTitle || "LogicPower",
      description: meta?.metaDescription,
    });
  }
});
