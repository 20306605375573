<template>
  <label
    class="base-checkbox"
    :class="{ error: validate?.$error && validate?.$dirty }"
  >
    <input v-model="model" type="checkbox" />
    <span class="base-checkbox__caption">
      <slot />
    </span>
  </label>
</template>

<script setup>
const model = defineModel();

const props = defineProps({
  validate: { type: Object, required: true },
  color: {
    type: String,
    required: false,
    default: "var(--color-primary-base)",
  },
});
</script>

<style lang="scss">
.filter-checkbox {
  width: 100%;
  padding: 8px 16px;

  @include on-hover {
    background-color: var(--color-sky-lighter);
  }

  &__compilation {
    display: flex;
  }

  &__title {
    @include font;
    word-break: break-word;

    @include bigMobile {
      @include font(14, 20);
    }
  }
}
</style>

<style scoped lang="scss">
.base-checkbox {
  position: relative;

  & input {
    height: 0;
    width: 0;

    position: absolute;
    left: 0;
    top: 50%;
    z-index: -1;

    opacity: 0;
    visibility: hidden;
  }

  & span::before {
    content: "";
    display: inline-block;

    @include fixedHW(24px);

    border: 1px solid var(--color-sky-base);
    border-radius: 4px;

    background-repeat: no-repeat;
    background-position: center;

    transition: border-color 0.2s ease;
  }

  &.error span::before {
    border-color: var(--color-red-dark);
  }

  & input:not(:checked) + span:hover::before {
    border-color: v-bind("color");
  }

  & input:checked + span::before {
    border-color: v-bind("color");
    background-color: v-bind("color");
    background-image: url("~~/assets/icons/check-icon.svg");
  }

  &__caption {
    @include flex-container(row, flex-start, center);
    gap: 16px;

    @include font(12, 16);

    user-select: none;
  }
}

:slotted(span) a {
  color: v-bind("color");
}
</style>
