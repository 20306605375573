<template>
  <div
    class="offer-card"
    :style="[
      { '--title-color': titleData.titleColor },
      { '--description-color': descriptionData.descriptionColor },
      { '--link-color': linkData.titleColor },
      { '--link-border-color': linkData.borderColor },
      { '--link-bg': linkData.bgColor },
      { '--bg': bgCard },
    ]"
  >
    <div class="offer-card__wrp">
      <div class="offer-card__content">
        <div class="offer-card__txt-wrp">
          <h4 v-if="titleData.title" class="offer-card__title">
            {{ titleData.title }}
          </h4>
          <p v-if="descriptionData.description" class="offer-card__description">
            {{ descriptionData.description }}
          </p>
        </div>
        <NuxtLink
          class="offer-card__link"
          :to="hrefLink.url"
          :target="hrefLink.target"
        >
          {{ linkData.title }}
        </NuxtLink>
      </div>

      <img
        class="offer-card__img"
        :src="useNormalizeCtfUrl(image.url)"
        :alt="image.alt"
      />
    </div>
  </div>
</template>

<script setup>
import { useLanguageStore } from "~/store/languageStore";
import { useNormalizeCtfUrl } from "~/utils/normalize";

const props = defineProps({
  card: { type: Object, required: true },
});

const languageStore = useLanguageStore();
const { getUserLanguage: lang } = storeToRefs(languageStore);

const titleData = computed(() => {
  return {
    title: getLocalizedName(props.card.title) || "",
    titleColor: props.card.colorTitle || "white",
  };
});

const descriptionData = computed(() => {
  return {
    description: getLocalizedName(props.card.description) || "",
    descriptionColor: props.card.colorText || "white",
  };
});

const linkData = computed(() => {
  return {
    title: getLocalizedName(props.card.textBtn) || "",
    titleColor: props.card.colorTextBtn || "white",
    borderColor: props.card.colorBorderBtn || "white",
    bgColor: props.card.backroundColorBtn || "white",
  };
});

const bgCard = computed(
  () => `url('${useNormalizeCtfUrl(props.card.backgroundImg.file.url)}')`,
);

const image = computed(() => {
  return {
    url: props.card.img.file.url || "",
    alt: getLocalizedName(props.card.img.title) || "image",
  };
});

const hrefLink = computed(() => {
  const url = props.card.urlBtn;

  if (/^https?:\/\//.test(url)) {
    return {
      url,
      target: "_blank",
    };
  }

  return {
    url: lang.value.urlPrefix + "/" + url,
    target: "_self",
  };
});
</script>

<style lang="scss" scoped>
.offer-card {
  --title-color: white;
  --description-color: white;
  --link-color: white;
  --link-border-color: transparent;
  --link-bg: transparent;
  --bg: none;

  height: 100%;

  position: relative;
  z-index: 10;

  background-color: var(--color-ink-base);
  background-image: var(--bg);
  background-repeat: no-repeat;
  background-size: cover;

  border-radius: 16px;

  &__wrp {
    height: 100%;

    padding: 16px 16px 24px;

    @include bigMobile {
      padding: 8px;
    }
  }

  &__content {
    height: 100%;

    position: relative;
    z-index: 5;

    @include flex-container(column, space-between, flex-start);

    gap: 16px;
  }

  &__txt-wrp {
    max-width: 205px;
    @include flex-container(column);

    gap: 8px;
  }

  &__title {
    @include font(32, 40, 700);
    color: var(--title-color);

    @include bigMobile {
      @include font(20, 26, 700);
    }
  }

  &__description {
    @include font();
    color: var(--description-color);
  }

  &__link {
    @include font(16, 20);
    color: var(--link-color);

    border-radius: 30px;
    border: 1px solid var(--link-border-color);

    background-color: var(--link-bg);

    box-shadow: 0 0 4px #00000066;

    padding: 8px 24px;
  }

  &__img {
    width: 50%;

    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
